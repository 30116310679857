import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

import SEO from "../../components/seo"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  title: {
    textAlign: "center",
  },
  gridItem: {},
  gridItem: {
    textDecoration: "none",
  },
  card: {},
  cardActionArea: {},
  cardMedia: {
    height: "300px",
    backgroundPosition: "center top",
  },
  cardContent: {
    height: "100px",
  },
}))

export default function InstructorsPage({ data }) {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth="md">
      <SEO title="Instructors" />
      <h1 className={classes.title}>Founders</h1>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        {data.founders.nodes.map(node => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={node.id}
            component={Link}
            to={node.frontmatter.path}
            className={classes.gridItem}
            zeroMinWidth
          >
            <Card className={classes.card}>
              <CardActionArea>
                {node.frontmatter.cover ? (
                  <CardMedia
                    className={classes.cardMedia}
                    image={node.frontmatter.cover.publicURL}
                    title={node.frontmatter.title}
                  />
                ) : (
                  <div className={classes.cardMedia} />
                )}
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6" align="center" color="primary">
                    {node.frontmatter.title}
                  </Typography>
                  {node.frontmatter.ranks.map(rank => (
                    <Typography
                      variant="subtitle2"
                      align="center"
                      color="secondary"
                      key={rank}
                    >
                      {rank}
                    </Typography>
                  ))}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <h1 className={classes.title}>Instructors</h1>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        {data.instructors.nodes.map(node => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={node.id}
            component={Link}
            to={node.frontmatter.path}
            className={classes.gridItem}
            zeroMinWidth
          >
            <Card className={classes.card}>
              <CardActionArea>
                {node.frontmatter.cover ? (
                  <CardMedia
                    className={classes.cardMedia}
                    image={node.frontmatter.cover.publicURL}
                    title={node.frontmatter.title}
                  />
                ) : (
                  <div className={classes.cardMedia} />
                )}
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6" align="center" color="primary">
                    {node.frontmatter.title}
                  </Typography>
                  {node.frontmatter.ranks.map(rank => (
                    <Typography
                      variant="subtitle2"
                      align="center"
                      color="secondary"
                      key={rank}
                    >
                      {rank}
                    </Typography>
                  ))}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export const query = graphql`
  {
    founders: allMdx(
      filter: { frontmatter: { tags: { eq: "founders" } } }
      sort: { fields: frontmatter___sort }
    ) {
      nodes {
        id
        frontmatter {
          title
          path
          sort
          ranks
          cover {
            publicURL
          }
        }
      }
    }
    instructors: allMdx(
      filter: { frontmatter: { tags: { eq: "instructors" } } }
      sort: { fields: frontmatter___sort }
    ) {
      nodes {
        id
        frontmatter {
          title
          path
          sort
          ranks
          cover {
            publicURL
          }
        }
      }
    }
  }
`
